import api from "../helpers/api";

class StudentService{

    async getStudents(data){
        return await api.get(`students`,{
            params:{
                campus_id:data.campus,
                department_id:data.department,
                program_id:data.program,
                school_id:data.school,
                academic_year_id:data.year,
                level_id:data.level
            }
        }).then(
            
            response => {
                return response.data.data;
            }
        )
    }

 
    //adding a single student 
    async addStudent(data){
        return await api.post(`student`,data).then(
            response => {
                return response.data.data;
            }
        )
    }

    // this function creates a new academic year for a student 
    async createSay(level_id,student_id){
        return await api.post(`create_say`,{
            level_id:level_id,
            student_id:student_id,
        }).then(
            response => {
                return response.data
            }
        )
        }

    // import students in an excel file 
    async importStudents(data){
        return await api.post(`import-students`,data).then(
            response => {
                return response.data.data;
            }
        )
    }

    async findStudent(data){
        console.log(data);
        return await api.get(`find_student`,{
            params:{
                matricule:data.matricule,
                names:data.names
            }
        }).then(
            response => {
                return response.data
            }
        )
    }

    async getStudent(id){
        return await api.get(`student/${id}`,{
           
        }).then(
            response => {
                return response.data
            }
        )
    }
    async updateStudentProfile(form){
        return await api.put(`student-profile/${form.id}`,
            {   
                name: form.name,
                dob:form.dob,
                gender:form.gender,
                tel:form.tel,
                email:form.email,
                home_address:form.home_address,
                language:form.language,
                marital_status:form.marital_status,
                school_attended:form.school_attended,
                fathers_name:form.fathers_name,
                mothers_name:form.mothers_name,
                parents_address:form.parents_address,
                parents_contact:form.parents_contact,
                country_id:form.country_id,
                division_id:form.division_id,
                region_id:form.region_id,
            }).then(
            response => {
                console.log(response.data)
                return response.data
            }
        )}
    
    async deleteStudent(student_id){
        return await api.delete('/delete_student',{
            params:{
                student_id:student_id
            }
        }).then(
            response => {
                return response.data
            }
        )
    }

    async getDownload(data){
        console.log("this is exporting students");
        return await api.get(`/students_excel`,{
            params:{
                campus_id:data.campus,
                department_id:data.department,
                program_id:data.program,
                school_id:data.school,
                academic_year_id:data.year
            },
            responseType: 'blob'
        }).then(
            response => {
                return response
            }
        )
    }

    async addCourse(course_id,student_id,
                    say_id,semester_id,level_id,department_id,session){
       
        return await api.post(`/add_course_registration`,{
            course_id,
            student_id,
            say_id,
            semester_id,
            department_id,
            level_id,
            session
        }).then(
            response => {
                return response
            }
        )
    }
    async deleteCourse(id){
        return await api.post(`/delete_course_registration`,{
            id
        }).then(
            response => {
                return response
            }
        )
    }

    async getStudentCourseListPdf(parameters){
        return await api.get(`student_course_list_pdf`,{
            params: {
                semester_id:parameters.semester_id,
                ay_id:parameters.ay_id,
                student_id:parameters.student_id,
                session:parameters.session
            },
                responseType: 'blob'
            
        },
       ).then(
            response => {
                return response.data
            }
        )}


        async updateAcadProfile(form){
            return await api.post(`student/update_academic`,
                {
                    campus_id:form.campus_id,
                    school_id:form.school_id,
                    department_id:form.department_id,
                    program_id:form.program_id,
                    level_id:form.level_id,
                    matricule:form.matricule,
                
                }).then(
                    response => {
                        console.log(response.data)
                        return response.data
                    }
                )
        }
        async updateStudentDocs(formData){
            return await api.post(`student/update_documents`,
                formData
            ).then(
                    response => {
                        console.log(response.data)
                        return response.data
                    }
                )
        }

        async changePassword(matricule,password){
            return await  api.post(`/student/change-password`, {
                matricule:matricule,
                password:password,
            }).then(
                response => {
                return response.data;
            })
        }


}
export default new StudentService;