import courseService from "../../services/course.service"


    export const state ={
        courses:[],
        data:{
            campus:'',
            school:'',
            department:'',
            program:'',
            level:'',
            year:''
             
          },

    }
    export const actions = {
        fetchCourses({commit}, payload){
            return  courseService.getCourses(payload).then(
                data => {            
                    // console.log(data.data);
                         
                    commit ('SET_COURSES', data.data.courses);
                    commit ('SET_PAYLOAD', payload);
                    return Promise.resolve(data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

    }
    export const mutations = {
        SET_COURSES(state, courses){
            state.courses = courses
        },
        SET_PAYLOAD(state, payload){
            state.payload = payload
        },
        AssetsFailed(){

        }
    }
    export const getters = {
        courses(state){
            return state.courses
        },
        payload(state){
            return state.payload
        },
    }


